import React from 'react';
import { graphql, StaticQueryDocument } from 'gatsby';
import get from 'lodash/get';
import ARTICLE_PREVIEW from '@/components/list/ArticlePreview.component';
import ContainerStyles from '@/components/container/Container.styles';
import { IQueryPageProps } from '@/common/types/page.types';
import { DefaultBlogPageProps } from '@/common/props/page.props';
import SEO from '../components/Seo';
import HERO from '../components/hero/Hero.component';

function BlogIndex({
  data,
  location,
}: IQueryPageProps<Queries.BlogQuery>): React.ReactNode {
  const posts = get(data, 'allContentfulBlogPost.nodes');
  const [page] = get(data, 'allContentfulPage.nodes');
  const { seoTitle, seoDescription, seoKeywords } = DefaultBlogPageProps;
  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        keywords={seoKeywords}
        pathname={location.pathname}
      />
      <HERO
        showTitle
        image={page.heroImage.gatsbyImage}
        title={page.title}
        subTitle={page.subTitle}
      />
      <ContainerStyles.PageContainer>
        <ARTICLE_PREVIEW posts={posts} />
      </ContainerStyles.PageContainer>
    </>
  );
}

export default BlogIndex;

export const blogPageQuery: StaticQueryDocument = graphql`
  query Blog {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        author {
          name
        }
        tags {
          tagText
        }
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
    allContentfulPage(
      filter: { contentful_id: { eq: "4ufHXrq0z67pc21myqR5KH" } }
    ) {
      nodes {
        title
        subTitle
        slug
        url {
          id
          url
        }
        heroImage: heroImage {
          gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 1680)
        }
      }
    }
  }
`;
